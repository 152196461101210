<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <div v-if="edit">
            <van-cell-group inset >
                <van-form>
                    <van-field
                        v-model="details.car_number"
                        name="name"
                        label="车牌号"
                        placeholder="车牌号"
                        :rules="[{ required: true, message: '请填写车牌号' }]"
                    />
                    <select-component
                        name="type"
                        lable="车辆类型"
                        :farr="all_car_size_type"
                        :fvalue_name="details.car_size_type_name"
                        @clk="confirm_car_size_type"
                    ></select-component>
                    <van-cell title="行驶证正面">
                        <template #label>
                            <uploader-component
                                :farr="details.xscard_file_a"
                                url="car_driver_car/xscard_file_a"
                                :max_count="1"
                                @update_img="update_xscard_file_a"
                            ></uploader-component>
                        </template>
                    </van-cell>
                    <van-cell title="行驶证反面">
                        <template #label>
                            <uploader-component
                                :farr="details.xscard_file_b"
                                url="car_driver_car/xscard_file_b"
                                :max_count="1"
                                @update_img="update_xscard_file_b"
                            ></uploader-component>
                        </template>
                    </van-cell>
                </van-form>
                <div v-if="this.id">
                    <div class="sub_button">
                        <van-button round block type="primary" @click="edit_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="back_details">取 消</van-button>
                    </div>
                </div>
                <div v-else>
                    <div class="sub_button">
                        <van-button round block type="primary" @click="add_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="$router.go(-1)">返回</van-button>
                    </div>
                </div>
            </van-cell-group>
        </div>
        <div v-else>
            <van-cell-group inset >
                <van-cell title="车牌号" :value="details.car_number" />
                <van-cell title="车辆类型" :value="details.car_size_type_name" />
                <van-cell title="行驶证正面">
                    <template #label>
                        <uploader-component
                            :farr="details.xscard_file_a"
                            :show_upload="false"
                            :deletable="false"
                        ></uploader-component>
                    </template>
                </van-cell>
                <van-cell title="行驶证反面">
                    <template #label>
                        <uploader-component
                            :farr="details.xscard_file_b"
                            :show_upload="false"
                            :deletable="false"
                        ></uploader-component>
                    </template>
                </van-cell>
            </van-cell-group>
            <div class="sub_button">
                <van-button round block type="info" @click="edit = true">修 改</van-button>
            </div>
            <div class="sub_button">
                <van-button round block type="danger" @click="on_del">删 除</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'
import UploaderComponent from '@/components/common/UploaderComponent.vue'

import { 
    car_driver_car_details_request,
    car_driver_car_del_request,
    car_driver_car_edit_request,
    car_driver_car_add_request,
    car_size_type_list_request
 } from '@/network/CarDriverCar'

export default {
    name:'CarDriverCarDetailsComponent',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            id: 0,
            details: {
                car_number: '',
                car_size_type_id: '',
                xscard_file_a: [],
                xscard_file_b: []
            },
            edit: false,
            all_car_size_type: []
        }
    },
    computed:{
        my_nav_bar_name() {
            return this.edit === true ? ( this.id ? '修改车辆' : '添加车辆') : '车辆详情'
        }
    },
    methods:{
        get_car_driver_car_details() {
            this.$store.commit('true_loading')
            car_driver_car_details_request(this.id)
                .then((s) => {
                    if (s.status === 0) {
                        this.details = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        on_del() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
            })
            .then(() => {
                this.$store.commit('true_loading')
                car_driver_car_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('删除成功!')
                                this.$router.go(-1);//返回上一层
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        edit_details() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                id: this.id,
                car_number: this.details.car_number,
                car_size_type_id: this.details.car_size_type_id,
                xscard_file_a: this.details.xscard_file_a,
                xscard_file_b: this.details.xscard_file_b
            }
            car_driver_car_edit_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('修改成功!')
                        this.get_car_driver_car_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        add_details() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                car_number: this.details.car_number,
                car_size_type_id: this.details.car_size_type_id,
                xscard_file_a: this.details.xscard_file_a,
                xscard_file_b: this.details.xscard_file_b
            }
            car_driver_car_add_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('添加成功!')
                        this.id = s.result.id
                        this.get_car_driver_car_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        back_details() {
            this.get_car_driver_car_details()
            this.edit = false
        },
        confirm_car_size_type(e) {
            this.details.car_size_type_id = e.id
        },
        update_xscard_file_a(arr) {
            this.details.xscard_file_a = arr
        },
        update_xscard_file_b(arr) {
            this.details.xscard_file_b = arr
        },
        get_all_car_size_type() {
            this.$store.commit('true_loading')
            car_size_type_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_car_size_type = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_all_car_size_type()

        this.id = this.$route.query.id
        if(this.id) {
            this.get_car_driver_car_details()
        }
        else
        {
            this.edit = true
        }
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent,
        UploaderComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
</style>